/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getRewardJackpot } from "./action"

interface initialStateType {
  loadingJackpot: boolean
  jackpot: any
  errorJackpot: string
}

const initialState: initialStateType = {
  loadingJackpot: false,
  jackpot: {},
  errorJackpot: ""
}

export const jackpotSlice = createSlice({
  name: 'jackpot',
  initialState,
  reducers: {},
  extraReducers: {
    //get reward jackpot
    [getRewardJackpot.pending.type]: (state) => {
      state.loadingJackpot = true
      state.jackpot = {}
    },

    [getRewardJackpot.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loadingJackpot = false;
      state.errorJackpot = "";
      state.jackpot = action.payload;
    },
    
    [getRewardJackpot.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingJackpot = false;
      state.errorJackpot = action.payload;
    }
  },
})

export default jackpotSlice.reducer
