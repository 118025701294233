import { configureStore } from '@reduxjs/toolkit'
import authReducer from 'features/Auth/auth.slice'
import profileReducer from 'features/Profile/profile.slice'
import coinUserReducer from 'features/Balance/coinUser.slice'
import cancelRaceReducer from 'features/ModalCancelRace/cancelRaceData.slice'
import weatherSlice from './redux/weather/weather.slice'
import tooltipInfoSlice from './redux/tooltip/tooltip.slice'
import horseInfoSelector from './redux/armorSkin/armorSkin.slice'
import jackpotSlice from './redux/jackpot/jackpot.slice'

const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    coinUser: coinUserReducer,
    cancelRaceData: cancelRaceReducer,
    weather: weatherSlice,
    tooltipInfo: tooltipInfoSlice,
    horseInfo: horseInfoSelector,
    jackpot: jackpotSlice
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
