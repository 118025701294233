/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit"
import jackpotApi from "apis/jackpot"
import { handleAsyncRequest } from 'utils/helper'

export const getRewardJackpot  = createAsyncThunk(
  'jackpot/getRewardJackpot',
  async (payload: any) => {
    const [error, result] = await handleAsyncRequest(jackpotApi.getRewardJackpot(payload))
    if (error) {
      return (error as any)?.message
    }
    return (result as any)?.data
  }
)
