/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input } from 'antd'
import { useSelector } from 'react-redux'
import raceApi from 'apis/raceApi'
import userApi from 'apis/userApi'
import { configs, constants, links } from 'apps'
import { STRING_CATEGORY, STRING_STATUS, TIME_CLOSE_MODAL } from 'apps/constants'
import {
  CAREER_BIRU_OLD_ICON,
  CAREER_KUDA_OLD_ICON,
  CAREER_MERAH_OLD_ICON,
  CARET_LEFT,
  CROWN_BRONZE,
  CROWN_BRONZE_BORDER,
  CROWN_GOLD,
  CROWN_GOLD_BORDER,
  CROWN_SILVER,
  CROWN_SILVER_BORDER,
  GAME_TOKEN_KUDA,
  GAME_TOKEN_MERAH,
  ICON_LOCK,
  JACKPOT_ANIM,
  ONE_LINE_ORANGE,
  RESULT_CONTRACT,
  RESULT_DETAIL
} from 'assets/images'
import { setCoinUser } from 'features/Balance/coinUser.slice'
import HorseModalRaceView from 'features/Horse/components/HorseModalRaceView'
import { setDataCancelRace } from 'features/ModalCancelRace/cancelRaceData.slice'
import {
  BoardViewTime,
  ChooseHorseModal,
  ConfirmChooseHorseModal,
  FollowRace,
  RaceTable,
  RequestLoginModal,
  ResultHorseModal,
  SchedulingLiveSocket,
  SchedulingLiveStarIn,
  TimeRace
} from 'features/Race/components'
import ConfirmOkModal from 'features/Race/components/ConfirmOkModal'
import RaceType from 'features/components/RaceType'
import { useAppDispatch, useAppSelector, useFetch, usePreventBodyScroll, useToggle } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { decode } from 'js-base64'
import _ from 'lodash'
import {
  EnterRaceError,
  EnterRaceErrorDupplicate,
  Gate,
  GetRaceListParams,
  GetRaceListPopupParams,
  JoinRaceBody,
  Race,
  RaceClassNumber,
  RaceStatus
} from 'models'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ClassTag, Modal } from 'shared'
import Button from 'shared/Button'
import { WS_MANAGER } from 'socket/socketClient'
import { openAndSchedulingDetailColumns, openDetailColumns, resultDetailColumns } from 'utils/columns'
import {
  capitalizeOnlyFirstLetter,
  formatTimeV2,
  getBackgroundRegion,
  getGuildTag,
  handleAsyncRequest,
  ordinalSuffixOf
} from 'utils/helper'
import { getImgWeather } from 'utils/weather'
import { convertFullDateLocal, convertShortDay, convertShortTime, convertShortTimeMinus } from 'utils/time'
import { weatherSelector } from 'apps/redux/weather/selector'
import ResultHorseModalStyled from './modalStyled'
import DetailStyled from './styled'
import { getTooltipInfo } from 'apps/redux/tooltip/action'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'

const getRegisteredQuantity = (gates: Gate[]) => gates.filter(gate => gate.horse !== null).length

function SchedulingLive() {
  const [joiningGate, setJoiningGate] = useState<number>(0)
  const { raceId } = useParams()
  const [triggerFetchRaceDetail, setTriggerFetchRaceDetail] = useState(false)
  const [isModalChooseHorseOpen, toggleIsChooseHorseModalOpen] = useToggle(false)
  const [isModalConfirmChooseHorseOpen, toggleConfirmIsChooseHorseModalOpen] = useToggle(false)
  const [isModalJoinRaceOpen, toggleIsModalJoinRaceOpen] = useToggle(false)
  const [isModalResultHorseOpen, toggleIsModalResultHorseOpen] = useToggle(false)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [isModalConfirmCancelOpen, toggleIsModalConfirmCancelOpen] = useToggle(false)
  const [isModalResultContract, isModalResultContractOpen] = useToggle(false)
  usePreventBodyScroll(isModalChooseHorseOpen)
  usePreventBodyScroll(isRequestLoginModalOpen)
  const [viewRace2D, setViewRace2D] = useState(false)
  const [disableRaceTable, setDisableRaceTable] = useState(true)
  const [firstTime, setFirstTime] = useState(0)
  const [timeCancelRace, setTimeCancelRace] = useState(0)
  const { data: raceDetail } = useFetch<Race, string>({ fetcher: raceApi.getRace, params: raceId || '' }, [
    raceId,
    triggerFetchRaceDetail
  ])
  const registeredGateQuantity = useMemo(() => getRegisteredQuantity(raceDetail?.gates ?? []), [raceDetail])
  const auth = useAppSelector(state => state.auth)
  const currentUser = useAppSelector(state => state.profile)
  const status = raceDetail?.status
  const totalPrice = raceDetail?.total_prize
  const [triggerReplay, toggleTriggerReplay] = useToggle(false)
  const [isRaceEnd, toggleIsRaceEnd] = useToggle(false)
  const [isRaceInProcess, toggleIsRaceInProcess] = useToggle(false)
  const [isRacePassword, toggleIsRacePassword] = useToggle(false)

  const [password, setPassword] = useState('')
  const [checkPassword, setCheckPassword] = useState(false)
  const [passwordMessage, setPasswordMessage] = useState('')
  const memoizedOpenAndSchedulingDetailColumns = useMemo(() => openAndSchedulingDetailColumns, [])
  const memoizedOpenDetailColumns = useMemo(() => openDetailColumns, [])
  const memoizedResultDetailColumns = useMemo(() => resultDetailColumns, [])
  const [isFinishedRace, toggleFinishedRace] = useState<boolean>(false)
  const [isRaceStatusClosed, toggleIsRaceStatusClosed] = useState<boolean>(false)
  const [isOpenHorseModal, setOpenHorseModal] = useState(false)
  const [horseId, setHorseId] = useState(0)
  const [horseConfirmId, setHorseConfirmId] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [messageError, setMessageError] = useState('not_enough')
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const getWeatherSelector = useSelector(weatherSelector)

  const intervalIdRef = useRef<number | null>(null);

  const date = new Date()
  const paramsFilterResult: GetRaceListPopupParams = {
    limit: 10,
    page: 1,
    startAt: convertFullDateLocal(date),
    endAt: convertFullDateLocal(date),
    startInstance: '1000',
    endInstance: '1000',
    raceClass: RaceClassNumber.Class1
    // sort: ['id-desc']
  }

  const convertToRaceGate = (race?: Race) => {
    if (!race) {
      return []
    }
    const raceId = race?.id

    const { gates, race_prizes, id, entry_fee, legacy_data, jackpot, jackpot_detail } = race

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gates.sort((gate1: any, gate2: any) => gate1?.race_position - gate2?.race_position)

    return gates.map((gate, index) => {
      if (gate.horse === null) {
        return { ...gate, gate: parseInt(gate.gate.slice(-2)) }
      }
      const htmlHorseString = `
          <div class="horse-rank d-flex align-items-center gap-2">
            <img src=${gate.horse.sub_avatar} class='horse-avatar' width="40px" />
            <span class="font-bold horse__name__box">
              <span class="horse__name">
                <span class='color-yellow'> ${gate.horse.guild ? getGuildTag(gate?.horse?.guild?.guild_tag) : ''}</span>
                ${gate.horse.name}
              </span>
            </span>
          </div>
        `

      const positionHorseHaveTop = (url_img: string, imgHorseTopBorder: string) => {
        return `
            <div class="horse-rank d-flex align-items-center justify-content-center gap-2">
                <img src=${imgHorseTopBorder} class='prize-border position-absolute' />
                <img src=${url_img} />
                <span class="font-bold">${ordinalSuffixOf(parseInt(gate.race_position))}</span>
            `
      }

      const positionHorseNoTop = () => {
        return `
            <div class="horse-rank d-flex align-items-center justify-content-center gap-2">
              <span class="font-bold">${ordinalSuffixOf(parseInt(gate.race_position))}</span>
            `
      }

      const handlePositionHorse = () => {
        if (parseInt(gate.race_position) === 1) {
          return positionHorseHaveTop(CROWN_GOLD, CROWN_GOLD_BORDER)
        }
        if (parseInt(gate.race_position) === 2) {
          return positionHorseHaveTop(CROWN_SILVER, CROWN_SILVER_BORDER)
        }
        if (parseInt(gate.race_position) === 3) {
          return positionHorseHaveTop(CROWN_BRONZE, CROWN_BRONZE_BORDER)
        }
        return positionHorseNoTop()
      }

      const styleFirstCount = `<span class="font-bold">${gate.horse.career.first_count}/</span>`
      const styleSecondCount = `<span class="font-bold">${gate.horse.career.second_count}/</span>`
      const styleThirdCount = `<span class="font-bold">${gate.horse.career.third_count}</span>`

      // const handleShowOwnerName = () => {
      //   return `<span className='font-bold'>${shortenUserName(gate.horse?.user.name)}</span>`
      // }
      // gate.horse?.user.name    
      
      return {
        cancel_count_down: id,
        experience_received: gate.experience_received,
        detailHorse: gate.horse.id,
        race_position: handlePositionHorse(),
        gate: `<span class="font-bold table-gate-no no-${parseInt(gate.gate.slice(-2))}">${parseInt(gate.gate.slice(-2))}</span>`,
        horse: htmlHorseString,
        horseNameArmorSkin: gate?.horse,
        blood_line: gate?.horse?.bloodline?.name,
        blood_line_name: `${gate?.horse?.bloodline?.name} - ${gate?.horse?.bloodline?.type}`,
        statistic: `<span class="font-bold career-horse">${gate.horse.career.total_number_of_races}</span> ${styleFirstCount}${styleSecondCount}${styleThirdCount}`,
        owner_name: gate.horse?.user?.name,
        owner_guild_tag: gate?.horse?.user?.guild_tag,
        user_id: gate.horse.user.id,
        race_prize: race_prizes[index]?.prize,
        getEntryFee: entry_fee,
        highlight: gate.horse.highlight,
        legacy_data,
        weapon_items_equipped: gate?.horse?.weapon_items_equipped,
        gender: gate.horse.gender,
        region: gate?.horse?.region,
        jackpot: {
          position: gate?.race_position,
          jackpot,
          jackpot_detail
        },
        raceId,
      }
    })
  }

  const handleChangePassword = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const passwordInput = e.target.value
    setPassword(passwordInput)
  }

  const checkRacePassword = async () => {
    const [error, result]: any = await handleAsyncRequest(raceApi.postRacePassword(raceId as string, { password: !_.isEmpty(password) ? password : null }))
    if (error) {
      setPasswordMessage(error.message || error.errors[0]?.message)
      setCheckPassword(false)
    }
    if (result) {
      toggleIsChooseHorseModalOpen()
      toggleIsRacePassword(false)
      setPasswordMessage('')
      setCheckPassword(true)
    }
  }

  const handleRegisterButtonClick = (gateNumber: number) => {
    if (!auth.isLogged) {
      toggleIsRequestLoginModalOpen(true)
      return
    }
    setJoiningGate(gateNumber)

    if (raceDetail && raceDetail.protected_race) {
      if (raceDetail.had_joined) {
        toggleIsChooseHorseModalOpen()
        toggleIsRacePassword(false)
        return
      }
      if (checkPassword) {
        toggleIsChooseHorseModalOpen()
        toggleIsRacePassword(false)
        return
      }
      setPasswordMessage('')
      toggleIsRacePassword(true)
      return
    } else {
      toggleIsChooseHorseModalOpen()
      toggleIsRacePassword(false)
    }
  }

  // count down cancel race
  const timerCancel = () => setTimeCancelRace(timeCancelRace - 1000)
  useEffect(() => {
    if (timeCancelRace <= 0) {
      return
    }
    const id = setInterval(timerCancel, 1000)
    return () => clearInterval(id)
  }, [timeCancelRace])

  // get time when access first room horse
  useEffect(() => {
    if (raceDetail && parseInt(raceDetail?.count_down) > 0) {
      const start_at = parseInt(raceDetail?.count_down)
      setFirstTime(start_at)
    }
    setTimeCancelRace(raceDetail?.count_down_cancel || 0) // prepare count down cancel race
  }, [raceDetail])

  const incrementCounter = () => {
    setFirstTime((c) => c - 1000);
  };

  useEffect(() => {
    dispatch(getTooltipInfo())
    if (intervalIdRef.current !== null) return;
    intervalIdRef.current = window.setInterval(() => {
      incrementCounter();
    }, 1000);

    return () => {
      if (intervalIdRef.current) {
        window.clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, []);

  // handle room have status pending
  const handleDisplayColumn = () => {
    let displayColumns = null
    if (status === 'OPEN') {
      displayColumns = memoizedOpenDetailColumns
    } else if( status === 'WAITING' || status === 'SCHEDULING'){
      displayColumns = memoizedOpenAndSchedulingDetailColumns
    } else {
      displayColumns = memoizedResultDetailColumns
    }
    return displayColumns
  }

  const gates = useMemo(() => convertToRaceGate(raceDetail), [raceDetail])
  const navigate = useNavigate()
  const openRace = links.race.open()
  const cancelRaceNotFound = links.notFound.index()

  // handle room have status pending
  useEffect(() => {
    function handleStatusRoom() {
      if (status === 'PENDING') return navigate(openRace)
      if (status === 'CANCEL') return navigate(cancelRaceNotFound)
    }
    handleStatusRoom()
  }, [status])

  const toggleTriggerFetchRaceDetail = () => setTriggerFetchRaceDetail(value => !value)
  const [timeLive, setTimeLive] = useState<number>(0)

  const onChangeTimeLive = (time: number) => {
    if (time === 0) {
      setIsLoading(true)
    } else setIsLoading(false)
    setTimeLive(time)
  }

  // handle room have status LIVE
  useEffect(() => {
    if (status === 'LIVE') {
      setDisableRaceTable(false)
    }
  }, [status])

  const fetchCoinUser = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return

    dispatch(setCoinUser(resultCoinUser.data))
  }

  async function fetchDataRaceResult() {
    try {
      const [, result] = await handleAsyncRequest(raceApi.getRaceResultContract(raceId || ''))
      return result?.data
    } catch (error) {
      console.error(error);
    }
  }

  const fetchDataCancelRace = async () => {
    const [, resultCancelRace] = await handleAsyncRequest(raceApi.getRaceCancel({ page: 1, limit: 20 }))
    if (!resultCancelRace) return
    dispatch(setDataCancelRace(resultCancelRace.data))
  }

  const TimeRaceResult = useCallback(() => {
    if (!raceDetail || status !== CLOSED) return null
    const timeElapsed = +(raceDetail.end_at || 0) - +(raceDetail.real_start_at || 0)
    return (
      <>
        <div className='info-left-item d-flex align-items-center'>
          <span className='title'>{t(`${NOTIFICATION_MESSAGE}.runAt`)}</span>
          <span className='value color-white'>
            {convertShortDay(parseInt(raceDetail.start_at))}{' '}
            {convertShortTime(parseInt(raceDetail.start_at))}
          </span>
        </div>
        {viewRace2D ? (
          ''
        ) : (
          <div className='info-left-item d-flex align-items-center'>
            <span className='title'>{t(`${NOTIFICATION_MESSAGE}.timeElapsed`)}</span>
            <span className='value color-white'>{convertShortTimeMinus(timeElapsed)}</span>
          </div>
        )}
      </>
    )
  }, [raceDetail, status, viewRace2D])

  // funtion handle display view time & live star in 2d
  function handleDisplayViewTimeRace() {
    let scheduledRaces = null

    if (status === 'LIVE') {
      scheduledRaces = (
        <SchedulingLiveSocket
          isRaceEnd={isRaceEnd}
          toggleIsRaceEnd={toggleIsRaceEnd}
          toggleIsRaceInProcess={toggleIsRaceInProcess}
          toggleTriggerFetchRaceDetail={toggleTriggerFetchRaceDetail}
          toggleFinishedRace={toggleFinishedRace}
          onChangeTimeLive={onChangeTimeLive}
          onDisableRaceTable={setDisableRaceTable}
          disableRaceTable={disableRaceTable}
          raceDetail={raceDetail as Race}
        />
      )
    }

    if (status === 'WAITING' || status === 'SCHEDULING') {
      scheduledRaces =
        viewRace2D === false ? (
          <BoardViewTime
            viewRace2D={setViewRace2D}
            idRaceDetail={raceDetail?.id}
            disableRaceTable={setDisableRaceTable}
            firstTime={firstTime}
            status={status}
            toggleIsRaceEnd={toggleIsRaceEnd}
          />
        ) : (
          <SchedulingLiveStarIn
            detailResult={raceDetail}
            triggerReplay={triggerReplay}
            isRaceEnd={isRaceEnd}
            toggleIsRaceEnd={toggleIsRaceEnd}
            toggleIsRaceInProcess={toggleIsRaceInProcess}
            status={status}
          />
        )
    }

    const open = ''
    const result =
      viewRace2D === false ? (
        <BoardViewTime
          viewRace2D={setViewRace2D}
          idRaceDetail={raceDetail?.id}
          disableRaceTable={setDisableRaceTable}
          firstTime={firstTime}
          status={status ?? 'CLOSED'}
          toggleIsRaceEnd={toggleIsRaceEnd}
        />
      ) : (
        <SchedulingLiveStarIn
          detailResult={raceDetail}
          triggerReplay={triggerReplay}
          isRaceEnd={isRaceEnd}
          toggleIsRaceEnd={toggleIsRaceEnd}
          toggleIsRaceInProcess={toggleIsRaceInProcess}
          status={status}
        />
      )

    let displayViewRace = null

    if (status === 'OPEN') {
      displayViewRace = open
    }

    if (status === 'SCHEDULING' || status === 'WAITING' || status === 'LIVE') {
      displayViewRace = scheduledRaces
    }

    if (status === 'CLOSED' || status === 'RESULT') {
      displayViewRace = result
    }

    return displayViewRace
  }

  useEffect(() => {
    if (status === 'CLOSED' || status === 'RESULT') {
      fetchCoinUser()
    }
  }, [status])

  const handleQuickView = (gateNumber: number) => {
    (() => {
      setOpenHorseModal(true)
      setHorseId(gateNumber)
    })()
  }

  const handleCloseHorseModal = () => {
    setOpenHorseModal(false)
  }

  // function handle display racetable
  function handleDisplayRaceTable() {
    let displayRaceTable = null
    if (!disableRaceTable) {
      displayRaceTable = ''
    }

    const clonedGates = gates.map(gate => {
      return gate
    })

    const defaultParams: GetRaceListParams = {
      limit: 10,
      page: 1,
      status: RaceStatus.SCHEDULING,
      freeRace: false,
      myHorse: false,
      search: ''
    }

    if (disableRaceTable) {
      displayRaceTable = (
        <RaceTable
          data={clonedGates}
          columns={handleDisplayColumn()}
          onRegisterButtonClick={handleRegisterButtonClick}
          loader={true}
          params={defaultParams}
          raisePage={defaultParams}
          status={status}
          onQuickView={handleQuickView}
          currentUser={currentUser}
          paramsFilterResult={paramsFilterResult}
          timeCancelRace={timeCancelRace}
          toggleIsModalConfirmCancelOpen={toggleIsModalConfirmCancelOpen}
          isModalConfirmCancelOpen={isModalConfirmCancelOpen}
        />
      )
    }

    return displayRaceTable
  }

  // handle replay button
  const handleReplayBtnClick = () => {
    toggleTriggerReplay()

    if (isRaceEnd) {
      toggleIsRaceEnd(false)
      toggleIsRaceInProcess(false)
      return
    }

    toggleIsRaceInProcess(true)
  }

  const handleRaceDetailStatus = (status?: string) => {
    if (status && status === 'RESULT') {
      return (status = 'CLOSED')
    }
    return status
  }

  const displayTotalPrizes = (raceDetail: Race) => {
    const legacyData = raceDetail?.legacy_data
    if (legacyData) {
      if (raceDetail?.entry_fee === 0) {
        if (raceDetail?.total_prize === 0) {
          return <div className='value font-bold color-white'>---</div>
        } else {
          return (
            <div className='value font-bold color-biru'>
              {raceDetail?.total_prize}
              <img src={CAREER_BIRU_OLD_ICON} alt='' className='game-token-merah' />
            </div>
          )
        }
      } else {
        if (raceDetail?.total_prize === 0) {
          return <div className='value font-bold color-merah'>---</div>
        } else {
          return (
            <div className='value font-bold color-merah'>
              {raceDetail?.total_prize}
              <img src={CAREER_MERAH_OLD_ICON} alt='' className='game-token-merah' />
            </div>
          )
        }
      }
    } else {
      if (raceDetail?.entry_fee === 0) {
        if (raceDetail?.total_prize === 0) {
          return <div className='value font-bold color-white'>---</div>
        } else {
          return (
            <div className='value font-bold color-biru'>
              {raceDetail?.total_prize}
              <img src={GAME_TOKEN_KUDA} alt='' className='game-token-merah' />
            </div>
          )
        }
      } else {
        if (raceDetail?.total_prize === 0) {
          return <div className='value font-bold color-merah'>---</div>
        } else {
          return (
            <div className='value font-bold color-merah'>
              {raceDetail?.total_prize}
              <img src={GAME_TOKEN_MERAH} alt='' className='game-token-merah' />
            </div>
          )
        }
      }
    }
    
  }
  //function handle StarIn Live Result
  const handleStarInLiveResult = () => {
    let displayResult = null
    if (status === 'LIVE' || (viewRace2D && status === 'WAITING')) {
      displayResult = <FollowRace status={status} firstTime={firstTime} />
    } else if (viewRace2D && status === 'CLOSED') {
      displayResult = (
        <div className='replay-btn-container d-flex align-items-center'>
          <button onClick={handleReplayBtnClick} className='replay-btn font-bold w-100 h-100'>
            <span className='color-primary'>
              {isRaceEnd ? 'Replay' : triggerReplay ? 'Stop' : isRaceInProcess ? 'Resume' : 'Play'}
            </span>
          </button>
        </div>
      )
    } else {
      displayResult = (
        <div className='info-right d-flex align-items-center'>
          <div className='info-right-item text-center'>
            <div className='title'>{t(`${NOTIFICATION_MESSAGE}.raceStatus`)}</div>
            <div className='value color-yellow'>{handleRaceDetailStatus(raceDetail?.status)}</div>
          </div>
          <div className='info-right-item text-center'>
            <div className='title'>{t(`${NOTIFICATION_MESSAGE}.entryFee`)}</div>
            {raceDetail && (raceDetail?.entry_fee === 0 ? (
              <span className='font-bold color-primary'>{t(`${NOTIFICATION_MESSAGE}.free`)}</span>
            ) : (
              <div className='value font-bold color-kuda'>
                {raceDetail?.entry_fee}
                {raceDetail?.legacy_data
                  ? <img src={CAREER_KUDA_OLD_ICON} alt='kuda' className='game-token-merah' />
                  : <img src={GAME_TOKEN_KUDA} alt='kuda' className='game-token-merah' />
                }
              </div>
            ))}
          </div>
          <div className='info-right-item text-center'>
            <div className='title'>{t(`${NOTIFICATION_MESSAGE}.totalPrizes`)}</div>
            {raceDetail && displayTotalPrizes(raceDetail as Race)}
          </div>
          {status === 'RESULT' ? (
            <div className='info-right-item text-center'>
              <div className='title'>{t(`${NOTIFICATION_MESSAGE}.horseNum`)}</div>
              <div className='value color-white'>12</div>
            </div>
          ) : (
            <div className='info-right-item text-center'>
              <div className='title'>{t(`${NOTIFICATION_MESSAGE}.horseNumber`)}</div>
              <div className='value color-white'>{registeredGateQuantity}</div>
            </div>
          )}
        </div>
      )
    }

    return displayResult
  }

  const handleCloseModalRaceResults = () => {
    return isModalResultContractOpen(false)
  }

  const handleCloseModal = () => {
    return toggleIsChooseHorseModalOpen(false)
  }

  const handleCloseModalConfirm = () => {
    return toggleConfirmIsChooseHorseModalOpen(false)
  }

  const handleCloseModalJoinRaceOpen = () => {
    return toggleIsModalJoinRaceOpen(false)
  }

  const handleCloseModalResult = () => toggleIsModalResultHorseOpen(false)

  const handleConfirmHorse = (id: number) => {
    setHorseConfirmId(id)
    toggleConfirmIsChooseHorseModalOpen(true)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isEnterRaceError = (candidate: any): candidate is EnterRaceError => {
    const isValid: boolean =
      candidate.code === constants.HTTP_STATUS.BAD_REQUEST && typeof candidate.message === 'string'

    return isValid
  }

  const isEnterRaceErrorDupicate = (candidate: any): candidate is EnterRaceErrorDupplicate => {
    const isValid: boolean =
      candidate.code === constants.HTTP_STATUS.BAD_REQUEST_429 && typeof candidate.message === 'string'

    return isValid
  }

  const onChooseHorse = async () => {
    if (raceDetail) {
      const joinRaceBody: JoinRaceBody = {
        horse_id: horseConfirmId,
        gate: joiningGate,
        password: !_.isEmpty(password) ? password : null
      }
      let message = ''
      const [error, responseData] = await handleAsyncRequest(raceApi.joinRace(raceDetail.id, joinRaceBody))
      if (error && isEnterRaceError(error)) {
        if (!error.message.includes('enough')) {
          message = error.message
        } else {
          if (error.message === 'Horse has not enough energy') {
            message = 'Horse has not enough energy'
          } else {
            message = 'not_enough'
          }
        }
      } else if (error && isEnterRaceErrorDupicate(error)) {
        if (error.message === 'Too Many Requests') {
          message = 'You can not join races repeatedly. Please try again later!'
        }
      }
      if (responseData) {
        setTriggerFetchRaceDetail(value => !value)
        message = 'completed'
        fetchCoinUser()
        fetchDataCancelRace()
      }
      setMessageError(message)
      toggleIsModalJoinRaceOpen(false)
      toggleIsModalResultHorseOpen(true)
    }
  }

  const renderResultHorseModal = () => {
    if (isModalResultHorseOpen && raceDetail) {
      const title = messageError === 'completed' ? 'completed' : 'warning'
      const message =
        messageError === 'completed' ? (
          <p>{t(`${NOTIFICATION_MESSAGE}.enterRaceSuccess`)}</p>
        ) : messageError === 'not_enough' ? (
          <p
            dangerouslySetInnerHTML={{
              __html: t(`${NOTIFICATION_MESSAGE}.notHaveEnough`, { fee: raceDetail.entry_fee })
            }}
          />
        ) : (
          messageError
        )
      const handleOk = () => {
        toggleIsModalResultHorseOpen(false)
      }

      setTimeout(handleCloseModalResult, TIME_CLOSE_MODAL)
      return (
        <ResultHorseModal
          toggleIsModalOpen={toggleIsModalResultHorseOpen}
          title={title}
          onCloseButtonClick={handleCloseModalResult}
          onOk={handleOk}
          message={message}
        />
      )
    }
  }

  const handleConfirmChooseHorse = () => {
    toggleConfirmIsChooseHorseModalOpen(false)
    if (raceDetail?.entry_fee === 0) {
      onChooseHorse()
    } else toggleIsModalJoinRaceOpen()
  }

  useEffect(() => {
    if (isFinishedRace && isRaceStatusClosed && disableRaceTable) {
      toggleTriggerFetchRaceDetail()
    }
  }, [isFinishedRace, isRaceStatusClosed, disableRaceTable])

  const { CLOSED, WAITING, SCHEDULING, LIVE } = RaceStatus

  const handleSocketChange = (message: { body: string }) => {
    const { data } = JSON.parse(message.body)
    const { newStatus, raceId: raceIdSocket } = data[Object.keys(data)[0]]
    if (raceIdSocket == decode(raceId as string)) {
      if (newStatus === CLOSED) {
        toggleIsRaceStatusClosed(true)
        setDisableRaceTable(true)
        setViewRace2D(false)
      }
      if (newStatus === WAITING || newStatus === SCHEDULING || newStatus === LIVE) {
        toggleTriggerFetchRaceDetail()
      }
    }
  }

  const handleSocketChangeRaceGate = () => {
    setTriggerFetchRaceDetail(value => !value)
  }

  useEffect(() => {
    const subscriptionRaceGate = WS_MANAGER.subscribe(
      `/topic/race-gate/${decode(raceId as string)}`,
      handleSocketChangeRaceGate
    )
    const subscriptionRaceStatus = WS_MANAGER.subscribe('/topic/race-status', handleSocketChange)
    const subscription = WS_MANAGER.subscribe('/topic/special-race-status', handleSocketChange)
    return () => {
      subscription.then(sub => sub?.unsubscribe())
      subscriptionRaceGate?.then(sub => sub?.unsubscribe())
      subscriptionRaceStatus?.then(sub => sub?.unsubscribe())
    }
  }, [])

  const timeElapsed = +(raceDetail?.end_at || 0) - +(raceDetail?.real_start_at || 0)
  const handleBtnBack = () => {
    setViewRace2D(false)
    setDisableRaceTable(true)
  }
  const closeModalRacePassword = () => {
    toggleIsRacePassword(false)
    setPassword('')
  }

  const checkRaceResutl = async () => {
    await fetchDataRaceResult().then((data) => {
      const urlBase = configs.linkBlockExplorerUrls
      const contractUrl = data?.contract_url
      if (contractUrl) {
        const fullUrlRace = urlBase + '/tx/' + contractUrl
        window.open(fullUrlRace, "_blank");
      } else {
        return isModalResultContractOpen(true)
      }
    })


  }
  const checkRaceResutlDetail = async () => {
    await fetchDataRaceResult().then((data) => {
      const raceDetailUrl = data?.detail_url
      if (raceDetailUrl) {
        window.open(raceDetailUrl, "_blank");
      } else {
        return isModalResultContractOpen(true)
      }
    })
  }

  const getImageDetail = (status: any = "", weatherCurrent: any = "", image: any = "", category: any = "") => {
    return getImgWeather((status === STRING_STATUS.OPEN || (status === STRING_STATUS.SCHEDULING && category === STRING_CATEGORY.SPECIAL)) ? weatherCurrent : image)
  }
  
  return (
    <DetailStyled>
      <div className='open-detail'>
        <div className='container'>
          <div className='name-container d-flex align-items-center'>
            {viewRace2D &&
              <div className='btn-back  me-5'>
                <div onClick={handleBtnBack} className='img-text-btn'>
                  <img src={CARET_LEFT} alt='' />
                  <span className='text-btn font-bold'>BACK</span>
                </div>
              </div>
            }
            <div className='name font-bold color-white text-uppercase'>{raceDetail?.name}</div>

            {/* Class */}
            <div>
              <ClassTag text={raceDetail?.racing_class.name ?? ''} isActive={true} customClass='ms-3' />
            </div>

            {/* MSP */}
            <div className='race-type'>
              <RaceType raceType={raceDetail?.race_type || ''} height={45} />
            </div>

            {/* weather */}
            {raceDetail &&
              <div className='weather-box'>
                <MemoizedLoadImageCommon
                  srcImg={getImageDetail(raceDetail?.status, getWeatherSelector?.weather?.name, raceDetail?.weather?.name, raceDetail?.category)}
                  alt={raceDetail?.weather?.name}
                />
              </div>}

            {/* Jackpot */}
            {raceDetail?.jackpot &&
              <div className='jackpot-box'>
                <MemoizedLoadImageCommon
                  srcImg={JACKPOT_ANIM}
                  alt={"Jackpot"}
                />
              </div>}

            {
              raceDetail?.protected_race && <img src={ICON_LOCK} alt='lock' className='icon-lock ms-3' width={40} />
            }

            {(status === 'CLOSED' && (totalPrice && totalPrice > 0)) && (
              <div className='btn-view-result'>
                <div onClick={checkRaceResutl} className='color-primary font-bold w-100'>
                  <img src={RESULT_CONTRACT} alt='' />
                </div>
              </div>
            )}
            {(status === 'CLOSED' && (totalPrice && totalPrice > 0)) && (
              <div className='btn-view-result'>
                <div onClick={checkRaceResutlDetail} className='color-primary font-bold w-100'>
                  <img src={RESULT_DETAIL} alt='' />
                </div>
              </div>
            )}
          </div>

          <div className='race-info-container d-flex flex-column flex-lg-row justify-content-between align-items-lg-center'>
            <div className='info-left'>
              <div className='left d-flex align-items-center'>
                <div className='info-left-item d-flex align-items-center'>
                  <span className='title'>Racecourse</span>
                  <span className={`value color-white ${getBackgroundRegion(raceDetail?.course?.name)}`}>
                    {/* {capitalizeOnlyFirstLetter(raceDetail?.course?.name)} */}
                    {raceDetail?.course?.name?.toUpperCase()}
                  </span>
                </div>
                <div className='info-left-item d-flex align-items-center'>
                  <span className='title'>Field type</span>
                  <span className='value color-white'>{capitalizeOnlyFirstLetter(raceDetail?.field_type.type)}</span>
                </div>
                <div className='info-left-item d-flex align-items-center'>
                  <span className='title'>Distance</span>
                  <span className='value color-white'>{raceDetail?.distance?.distance.toLocaleString() ?? '0'}m</span>
                </div>
                <TimeRaceResult />
                {status === CLOSED && viewRace2D && (
                  <div className='info-left-item d-flex align-items-center'>
                    <span className='title'>{t(`${NOTIFICATION_MESSAGE}.timeElapsed`)}</span>
                    <span className='value color-white'>
                      <TimeRace
                        toggleIsRaceEnd={toggleIsRaceEnd}
                        reset={isRaceEnd && isRaceInProcess}
                        isStopped={isRaceEnd ? isRaceEnd : !triggerReplay}
                        timeElapsed={timeElapsed}
                        isRaceInProcess={isRaceInProcess}
                        isRaceEnd={isRaceEnd}
                      />
                    </span>
                  </div>
                )}
                {status === LIVE && (
                  <div>
                    <span className='title'> {t(`${NOTIFICATION_MESSAGE}.timeElapsed`)}: </span>
                    <span className='color-primary time-race'>
                      {isLoading ? '00:00:0' : formatTimeV2(timeLive, true)}
                    </span>
                  </div>
                )}
              </div>
            </div>
            {handleStarInLiveResult()}
          </div>
        </div>
        <div className='line-grey'></div>
        <div className='container'>
          {handleDisplayViewTimeRace()}

          {status !== 'LIVE' && handleDisplayRaceTable()}
        </div>
      </div>
      {isModalChooseHorseOpen && raceDetail && (
        <ChooseHorseModal
          race={raceDetail}
          toggleIsModalOpen={toggleIsChooseHorseModalOpen}
          joiningGate={joiningGate}
          setTriggerFetchRaceDetail={setTriggerFetchRaceDetail}
          hadJoined={raceDetail.had_joined}
          onCloseButtonClick={handleCloseModal}
          onConfirmHorse={handleConfirmHorse}
        />
      )}
      {isModalJoinRaceOpen && raceDetail && (
        <ResultHorseModal
          toggleIsModalOpen={toggleIsModalJoinRaceOpen}
          onCloseButtonClick={handleCloseModalJoinRaceOpen}
          onOk={onChooseHorse}
          message={
            <p
              dangerouslySetInnerHTML={{
                __html: t(`${NOTIFICATION_MESSAGE}.needGetKUDA`, { fee: raceDetail.entry_fee })
              }}
            />
          }
          btnCancel={true}
        />
      )}
      {renderResultHorseModal()}
      {isModalConfirmChooseHorseOpen && raceDetail && (
        <ConfirmChooseHorseModal
          race={raceDetail}
          toggleIsModalOpen={toggleConfirmIsChooseHorseModalOpen}
          toggleIsChooseHorseModal={toggleIsChooseHorseModalOpen}
          joiningGate={joiningGate}
          setTriggerFetchRaceDetail={setTriggerFetchRaceDetail}
          hadJoined={raceDetail.had_joined}
          onCloseButtonClick={handleCloseModalConfirm}
          horseConfirmId={horseConfirmId}
          onConfirm={handleConfirmChooseHorse}
        />
      )}
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
      {isOpenHorseModal && (
        <HorseModalRaceView
          horseId={horseId}
          onCloseButtonClick={handleCloseHorseModal}
          myName={auth.user_id}
          atProfile={true}
          raceId={raceId as string}
        />
      )}
      {isRacePassword && (
        <ConfirmOkModal
          onCloseButtonClick={closeModalRacePassword}
          onConfirm={checkRacePassword}
          title='Race Password'
          message={
            <div className='password-input'>
              <div className='d-flex'>Password</div>
              <Input.Password placeholder="Password" value={password} onChange={handleChangePassword} prefix={<img src={ICON_LOCK} alt='' width={30} />} />
              {passwordMessage?.length > 0 && <div className='message-error'> {passwordMessage}</div>}

            </div>
          }
          btnOk='Join race'
          btnCancel='Cancel'
          isDisabled={_.isEmpty(password)}
        />
      )}
      {isModalResultContract && (
        <Modal onOverlayClick={isModalResultContractOpen}>
          <ResultHorseModalStyled>
            <div className='choose-horse-modal'>
              <div className='race-name-container'>
                <p className='race-name color-orange text-uppercase d-flex align-items-start flex-column'>
                  <span className='d-block'>warning</span>
                  <img src={ONE_LINE_ORANGE} alt='' />
                </p>
              </div>
              <div className='confirm-horse'>
                <div className='confirm-horse-message text-white'>Data is being processed....</div>
                <div className='confirm-horse-btns d-flex align-items-center justify-content-end'>

                  {<Button buttonName={'Ok'} onClickButton={handleCloseModalRaceResults} width={150} />}
                </div>
              </div>
            </div>
          </ResultHorseModalStyled>
        </Modal>
      )}
    </DetailStyled>
  )
}

export default SchedulingLive
