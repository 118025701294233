/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApiResponse,
} from 'models'
import axiosClient from './axiosClient'

const jackpotApi = {
  getRewardJackpot(payload: any): Promise<ApiResponse<any>> {
    const url = `/races/${payload.raceId}/${payload.horseId}/jackpot-detail`
    return axiosClient.get(url)
  },
}

export default jackpotApi
